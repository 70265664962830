@import '../../vars.scss';

.subtitlesContainer {
  margin: 0;
  flex: 1;
  width: 100%;
  background-color: gray;
  display: flex; 
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  overflow-y: scroll;
}

.container {
  //position: absolute;
  //bottom: 10px;
  //left: 50%;
  //transform: translate(-50%);
  padding: 15px;
  height: 280px;
  width: 90%;
  border-radius: 5px;
  box-shadow: 5px 5px 9px 1px rgba(0,0,0,0.2);
  overflow-y: scroll;
  background-color: rgba($text-color, 1);
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.subtitles::-webkit-scrollbar {
  display: none;
}
.text {
  vertical-align: center;
  letter-spacing: 2px;
  line-height: 4rem;
  font-size: 2.5rem;
  font-weight: 500;
}
.text:last-child {
  margin-bottom: 0;
}
.color0 {
  color: $subtitles-color;
}
.color1 {
  color: $text-color;
}
.reactMic {
  opacity: 0;
  width: 0;
  height: 0;
}

.secondaryLanguageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.secondaryLanguageButton{
  background: none;
  border:none;
  cursor: pointer;
}

.secondaryLanguageHeader{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .body {
    overflow-y: scroll;
  }
  .container {
    width: 95%;
    height: 85%;
    overflow-y: scroll;
  }

  .text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}