@import '../../vars';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
}

form {
  display: flex;
  flex: 1;
}

.separator {
  height: 80%;
  width: 1px;
  background-color: $text-color;
  opacity: 0.5;
}

.accountContainer {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.accountText {
  height: 1.2rem;
  font-weight: bold;
  color: $text-dark;
  font-size: 1rem;
  box-sizing: border-box;
}
.accountText:hover {
  border-bottom: 2px solid;
}
.accountIcon {
  width: 20px;
  margin-right: 15px;
}
