@import '../../vars.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

h1 {
  color: $text-color;
  margin-bottom: 40px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type=text],input[type=password] {
  border-radius: 5px;
  border-width: 0.5px;
  border-color: $text-color;
  color: $text-color;
  padding: 10px;
  width: 300px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
input[type=submit] {
  background: $primary-color;
  color: $secondary-color;
  border-radius: 5px;
  border-width: 0.5px;
  margin-top: 20px;
  padding: 15px;
  width: 300px;
  text-shadow: none;
  font-size: 1.3em;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 6px 6px 8px 4px #00000022;
}
