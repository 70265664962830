@import '../../vars';

.title {
  color: $secondary-color;
  font-size: 1.2rem;
  margin-bottom: 2%
}
p {
  color: $text-color;
  margin: 0;
}

.container {
  margin: 0;
  flex: 1;
  height: 100dvh;
  width: 100%;
  background-color: gray;

}

.header {
  // height: 12%;
  width: 100%;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 38px;
  padding-right: 38px;
  color: $secondary-color;
  font-size: 1.0em;
  font-weight: bold;
  flex-wrap: wrap;
  flex-direction: column;
  span {
    font-size: 0.8em;
  }
}

.body {
  height: 88%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.button {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 20px;
  border: 2px solid white;
  box-sizing: border-box;
}
.button:hover {
  cursor: pointer;
}
.button p {
  color: $secondary-color;
}
.notReadyButton {
  opacity: 0.4;
}
.notReadyButton {
  opacity: 0.4;
}

.speakerButton {
  margin-top: 30px;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background-color: #005500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.speakerButton:hover {
  cursor: pointer;
}
.speakerButton p {
  color: $secondary-color;
}

.reconnectButton {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background-color: #005500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.reconnectButton:hover {
  cursor: pointer;
}
.reconnectButton p {
  color: $secondary-color;
}

.leaveButton {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background-color: darkred;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.leaveButton:hover {
  cursor: pointer;
}
.leaveButton p {
  color: $secondary-color;
}

.participants {
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  background-color: $secondary-color;
  box-shadow: 3px 3px 5px 2px #00000011;
  border-radius: 5px;
  box-sizing: border-box;
}
.userLine {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #cccccc;
}
.userLine:last-child {
  border-bottom: none;
}
.userName {
  font-size: .9rem;
  line-height: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 85%;
}
.userLanguage {
  background-color: #dddddd;
  color: $text-dark;
  padding: 2px;
  border-radius: 3px;
  margin-left: 10px;
  width: 20px;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: 600;
}

.settings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.settings > * {
  margin-bottom: 2%; 
}

.videos {
  flex: .85;
  position: relative;
  //width: 100%;
  background-color: black;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 5px 5px 9px 1px rgba(0,0,0,0.3);
}

.subscriber {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.publisher {
  position: absolute;
  width: 250px;
  height: 160px;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: 5px 5px 9px 1px rgba(0,0,0,0.2);
  overflow: hidden;
}

.controls {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
  width: 300px;
  height: 50px;
  background-color: #333333cc;
  opacity: .5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  border-radius: 5px;
}
.controls:hover {
  opacity: 1;
}

.timer {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 80px;
  padding: 10px;
  background-color: #ffffffbb;
  z-index: 20;
  border-radius: 5px;
  color: $text-dark;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
}

.muted {
  width: 40px;
  cursor: pointer;
  color: white;
}
@media only screen and (max-width: 600px) {
  .container {
    //height: 100vh;
  }
  .header {
    font-size: 0.7em;
  }
  .body {
    flex-direction: column;
    align-items: center;
  }
  .settings {
    width: 90%;
  }
}
