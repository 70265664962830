@import '../../vars';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.backContainer {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.arrow {
  width: 30px;
}
.backText {
  margin-left: 5px;
  font-weight: 600;
}

.title {
  margin: 0;
}
.subtitle {
  margin-top: 3rem;
}
.overlay {
  position: fixed;
  inset: 0;
  z-index: 40;
}

.modal {
  position: absolute;
  top: 2rem;
  right: 2rem;
  min-width: 15rem;
  max-width: 20rem;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #eeeeee66;
  padding: 1rem;
  border-radius: .5rem;
  box-sizing: border-box;
}
.listContainer {
  margin-bottom: 20px;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 4px 4px 8px 4px #00000022;
}
.list {
  list-style-type: none;
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}
.list:last-child {
  margin-bottom: 0;
}
